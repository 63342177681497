.intro-head,
.intro-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  clip-path: inset(100% 0 0 0);
}

.intro-head {
  font-size: 40px;
  top: 33%;
  font-weight: 600;
  padding-bottom: 50px;
  text-align: center;
}

.intro-text {
  font-size: 160px;
  top: 35%;
  font-weight: 700;
  opacity: 0.5;
}

.entered .intro-head,
.entered .intro-text {
  clip-path: inset(0);
  transition: all 0.6s var(--easing-authentic-motion) 0.7s;
}

.container {
  display: flex;
}

.section-one {
  padding: 60px 40px 135px 150px;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.section-two {
  padding: 60px 80px 60px 150px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f3f3f3;
}

.section-three {
  margin: 80px 0 0;
  flex-direction: column;
  position: relative;
}

.description {
  padding-left: 150px;
}

.who-we-are-description {
  max-width: 285px;
  padding: 0 20px 0 80px;
  font-size: 22px;
  z-index: 100;
  line-height: 1.6;
  color: #000;
}

.image {
  margin: 0;
}

.intro-home {
  background-color: #f3f3f3;
  min-height: 100vh;
}

.intro-image {
  background-image: url('../images/intro.jpg');
  background-size: cover;
  background-position: 50% 50%;
  min-height: 100vh;
  transform: translateY(100%);
}

.entered .intro-image {
  transform: translateY(0);
  transition: transform 1.5s ease;
}

.who-we-are {
  display: flex;
  align-items: center;
}

.who-we-are-image {
  z-index: 100;
  clip-path: inset(0 10% 0 10%);
  transform: translateY(15%) scale(0.75);
  transform-origin: bottom;
  transition: transform 0.8s var(--easing-authentic-motion),
    clip-path 0.8s var(--easing-authentic-motion);
}

.effect .who-we-are-image {
  clip-path: inset(0);
  transform: translate(0) scale(1);
}

.section-title {
  font-size: 80px;
  font-weight: 500;
  line-height: 1.08;
  color: orange;
  margin: 40px 0;
  clip-path: inset(0 0 100% 0);
  transition: clip-path 0.8s var(--easing-authentic-motion);
}

.effect .section-title {
  clip-path: inset(0);
}

.who-we-are .section-title {
  margin: 0 0 20px;
}

.what-we-do .section-title {
  margin: 0 0 60px;
}

.areas-of-focus .section-title {
  margin: 0 0 20px;
}

.column-one {
  align-items: flex-start;
}

.column-one,
.column-three {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.who-we-are .responsive-image {
  max-width: 100%;
  height: auto;
}

.what-we-do-image {
  width: 100%;
  padding-bottom: 120%;
  position: relative;
  transform: scale(0.5);
  transition: transform 0.6s var(--easing-authentic-motion),
    opacity 0.6s var(--easing-authentic-motion);
  opacity: 0;
}

.effect .what-we-do-image {
  transform: scale(1);
  opacity: 1;
}

.what-we-do-image .responsive-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-home .title {
  white-space: nowrap;
  line-height: 1.5;
  display: inline-block;
  padding: 6px 14px;
  background-color: #355e3b;
  color: #fff;
  border: 1px solid #355e3b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.4s var(--easing-authentic-motion);
}

.page-home .title:hover {
  border: 2px solid orange;
  background-color: orange;
  color: #fff;
  transform: scale(1.08);
}

.line {
  height: 575px;
  width: 1px;
  background-color: #355e3b;
  transform: translateY(-60px);
  transition: clip-path 1s var(--easing-authentic-motion);
}

.entered .line {
  clip-path: inset(0);
}

.exited .line {
  clip-path: inset(0 0 100% 0);
}

.sphere {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 400px;
  height: 400px;
  filter: blur(50px);
  will-change: transform;
  opacity: 0.9;
  border-radius: 50%;
  background-color: orange;
  isolation: isolate;
  user-select: none;
  transition: all 0s linear;
}

.what-we-do-detail {
  overflow: hidden;
}

.detail-title {
  font-size: 24px;
  margin: 25px 0 15px;
  line-height: 1.2;
  font-weight: 500;
  color: #355e3b;
}

p {
  line-height: 1.5;
  font-weight: 300;
}

.detail-text {
  color: #355e3b;
  margin: 0;
}

.detail-title,
.detail-text {
  transform: translateY(200%);
  transition: transform 1s var(--easing-authentic-motion) 0.2s;
}

.effect .detail-title,
.effect .detail-text {
  transform: translateY(0);
}

.description-text {
  max-width: 450px;
  margin: 0 auto 0 0;
  color: #000;
}

.icons {
  margin: 60px 0 80px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.row-two {
  gap: 55px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.what-we-do.row-three {
  width: 100%;
  margin: 80px 0 0;
  text-align: center;
}

.section-four {
  padding: 0 70px 100px 0;
  box-sizing: border-box;
  display: block;
}

.history-content {
  display: flex;
}

.history-image {
  overflow: hidden;
  transform: translateX(-100%);
  transition: transform 0.8s var(--easing-authentic-motion);
}

.effect .history-image {
  transform: translateX(0);
}

.section-four .image {
  max-width: 700px;
  max-height: 550px;
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 0 100px 0 0;
}

.section-four img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
}

.right-side {
  padding-left: 135px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.right-side .section-title {
  margin: 15px 0;
}

.right-side .description-text {
  margin: 0 0 30px;
  max-width: 295px;
}

.right-side .who-we-are.title {
  width: fit-content;
}

.footer {
  margin: 0 40px;
  overflow: hidden;
  padding-bottom: 70px;
}

.footer-wrapper-container {
  transform: translateY(150%);
  transition: transform 0.6s var(--easing-authentic-motion);
  transition-delay: 40ms;
}

.effect .footer-wrapper-container {
  transform: translateY(0%);
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #355e3b;
  transition: clip-path 1s var(--easing-authentic-motion);
  clip-path: inset(0 45% 0 45%);
}

.effect .horizontal-line {
  clip-path: inset(0);
}

.footer-wrapper {
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 180px;
}

.link-effect {
  text-decoration: none;
  line-height: 24px;
}

.column-title {
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto 10px 0;
  color: #355e3b;
  line-height: 24px;
}

.column-logo img {
  width: 275px;
  height: auto;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.column-text {
  font-size: 16px;
  color: grey;
}

.link-effect:hover {
  color: orange;
}

.gallery {
  padding: 80px 120px;
  transition: 0.5s;
}

.gallery h3 {
  margin: 0;
}

.gallery-img-wrapper {
  display: flex;
  width: 100%;
  padding: 4% 2%;
  box-sizing: border-box;
  height: 55vh;
  transition: 0.5s;
}

.gallery-img-item {
  flex: 1;
  overflow: hidden;
  transition: 0.5s;
  margin: 0 2%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.6);
  line-height: 0;
}

.gallery-img-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-img-item:hover {
  flex: 1 1 65%;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media (width > 1800px) {
  .intro-text {
    font-size: 240px;
    top: 30%;
  }

  .line {
    height: 60vh;
  }

  .column-two {
    padding: 0 20px 0 140px;
  }

  .section-four {
    padding: 0 0 100px;
  }

  .section-four .image {
    max-width: 1040px;
    max-height: 690px;
  }

  .footer-title {
    font-size: 100px;
  }

  .container h3 {
    font-size: 120px;
  }

  .gallery h3 {
    font-size: 120px;
  }

  .gallery-img-wrapper {
    height: 100vh;
  }
}

@media (width <=1024px) {
  .intro-head {
    top: 36%;
  }

  .intro-text {
    font-size: 130px;
    top: 40%;
  }

  .column-two {
    font-size: 16px;
    padding-left: 45px;
    max-width: 205px;
  }

  .section-title {
    font-size: 60px;
  }

  .row-two {
    gap: 40px;
  }

  .who-we-are-description {
    max-width: 205px;
    font-size: 16px;
    padding: 0 20px 0 45px;
  }

  .who-we-are-image {
    width: 100%;
  }

  .section-one,
  .section-two {
    padding: 60px 70px 130px 140px;
  }

  .section-four .image {
    max-width: 500px;
    height: 390px;
  }

  .gallery h3 {
    font-size: 60px;
  }

  .footer-wrapper {
    gap: 80px;
  }
}

@media (width <= 850px) {
  .intro-head,
  .intro-text {
    transition: font-size 0.3s ease 0.1s;
  }

  .intro-head {
    font-size: 24px;
    top: 38%;
  }

  .intro-text {
    font-size: 60px;
    top: 41%;
  }

  .section-one {
    display: flex;
    flex-direction: column;
    padding: 30px 20px 60px;
    align-items: flex-start;
  }

  .who-we-are-description {
    max-width: 500px;
    padding: 0;
  }

  .who-we-are.column-one {
    max-width: 500px;
  }

  .sphere {
    width: 185px;
    height: 185px;
    filter: blur(40px);
  }

  .section-two {
    padding: 30px 20px 60px;
  }

  .what-we-do.row-three {
    text-align: left;
    margin: 20px 0 0;
  }

  .section-title {
    font-size: 50px;
  }

  .column-two {
    max-width: 500px;
    padding: 0;
    font-size: 16px;
    z-index: 100;
    line-height: 1.6;
    margin: 30px 0;
  }

  .section-one .title {
    margin-top: 50px;
  }

  .line {
    display: none;
  }

  .what-we-do.row-two {
    grid-template-columns: 1fr;
  }

  .description {
    padding: 0 20px;
  }

  .description-text {
    max-width: 100%;
  }

  .footer .column.mobile {
    margin-top: 20px;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .section-four {
    padding: 20px 20px 60px;
  }

  .history-content {
    flex-direction: column;
  }

  .right-side {
    max-width: 100%;
    padding: 0;
  }

  .right-side .description-text {
    max-width: 100%;
  }

  .section-four .image {
    margin-bottom: 30px;
    max-width: 100%;
    max-height: 260px;
  }

  .gallery {
    padding: 20px 20px 60px;
  }

  .gallery-heading {
    margin-bottom: 25px;
  }

  .gallery-img-wrapper {
    padding: 0 0 10px;
    gap: 10px;
  }

  .gallery-img-item {
    margin: 0;
    box-shadow: none;
  }

  .footer-wrapper {
    padding-top: 20px;
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
}

@media (width <=576px) {
  .sphere {
    display: none;
  }
}
